import apiClient from './basicapi'

function getSettings() {
  return apiClient.get('ForumsAdmin/settings').then((res) => res.data)
}

function getHolidaySettings() {
  return apiClient.get('ForumsAdmin/holiday-settings').then((res) => res.data)
}

function putSettings(disableAutoPublication) {
  return apiClient
    .put(`ForumsAdmin/settings`, {
      disableAutoPublication: disableAutoPublication,
    })
    .then((res) => res.data)
}

function putHolidaySettings(holidayModusEnabled) {
  return apiClient
    .put(`ForumsAdmin/holiday-settings`, {
      holidayModusEnabled: holidayModusEnabled,
    })
    .then((res) => res.data)
}

export { getSettings, getHolidaySettings, putSettings, putHolidaySettings }
