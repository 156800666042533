import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Button, FormControlLabel, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl, FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const validationSchema = Yup.object({
  disableAutoPublication: Yup.bool(),
  holidayModusEnabled: Yup.bool(),
})

function SettingsForm({ settings, holidaySettings, onSubmit }) {
  const classes = useStyles()
  const intl = useIntl()
  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      disableAutoPublication: settings.disableAutoPublication,
      holidayModusEnabled: holidaySettings.holidayModusEnabled,
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit(values) {
      if (onSubmit) onSubmit(values)
    },
  })

  function onHolidayChange() {
    const isHoliday = !values.holidayModusEnabled
    setFieldValue('holidayModusEnabled', isHoliday)
    setFieldValue('disableAutoPublication', isHoliday)
  }

  return (
    <form className={classes.form}>
      <FormControlLabel
        control={
          <Switch
            checked={values.disableAutoPublication}
            onChange={handleChange}
            name="disableAutoPublication"
            color="primary"
          />
        }
        label={intl.formatMessage({
          id: 'settings.mustaccept.label',
        })}
      />
      <FormControlLabel
        control={
          <Switch
            checked={values.holidayModusEnabled}
            onChange={onHolidayChange}
            name="holidayModusEnabled"
            color="primary"
          />
        }
        label={intl.formatMessage({
          id: 'settings.holidaymode.label',
        })}
      />
      <Box mt={4} width="100%" textAlign="left">
        <Button
          onClick={handleSubmit}
          variant="contained"
          size="large"
          color="primary"
        >
          <FormattedMessage id="button.send" />
        </Button>
      </Box>
    </form>
  )
}

SettingsForm.propTypes = {
  onSubmit: PropTypes.func,
  settings: PropTypes.object,
  holidaySettings: PropTypes.object,
}

export default SettingsForm
