import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import SettingsForm from '@components/forms/settings'
import { useIntl } from 'react-intl'

import {
  getSettings,
  getHolidaySettings,
  putSettings,
  putHolidaySettings,
} from '@services/settingsservice'
import WarningNote from '@objects/warningnote'
import LoadingSkeleton from '@objects/loadingSkeleton'

export const frontmatter = {
  title: 'Einstellungen',
}

function SettingsPage() {
  const intl = useIntl()
  const isMountedRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState({})
  const [holidaySettings, setHolidaySettings] = useState({})

  useEffect(() => {
    isMountedRef.current = true
    fetchSettings()
    return () => {
      isMountedRef.current = false
    }
  }, [])

  function fetchSettings() {
    setLoading(true)
    Promise.all([getHolidaySettings(), getSettings()]).then((settingsArray) => {
      if (isMountedRef.current) {
        setHolidaySettings(settingsArray[0])
        setSettings(settingsArray[1])
      }
      setLoading(false)
    })
  }

  function applySettings(settings) {
    putHolidaySettings(settings.holidayModusEnabled).then(() => fetchSettings())
    putSettings(settings.disableAutoPublication).then(() => fetchSettings())
  }

  return (
    <React.Fragment>
      <WarningNote
        text={intl.formatMessage({
          id: 'settings.introtext',
        })}
      />
      <LoadingSkeleton loading={loading}>
        <SettingsForm
          settings={settings}
          holidaySettings={holidaySettings}
          onSubmit={applySettings}
        />
      </LoadingSkeleton>
    </React.Fragment>
  )
}

SettingsPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default SettingsPage
